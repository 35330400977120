.container {
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0;
  min-width: 30vw;
  max-height: 35vh;
  box-shadow: var(--subtleBoxShadow);
  overflow-y: auto;
}

.topicSelectionButton {
  border: none;
  border-radius: 0px;
}

.topicSelectionButton:hover {
  opacity: 0.6;
}
