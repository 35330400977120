.overlay {
  animation: slideUp 0.3s;
  font-family: var(--fontFamilySansSerif);
  z-index: var(--zIndexLevelFive);
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  margin-bottom: var(--mobileBottomNavHeight);
}

.content {
  width: 100vw;
  margin: 0 auto;
  padding: var(--pageGutter);
  background: var(--colorBG);
  overflow: auto;
  height: 100%;
  padding-bottom: var(--mobileBottomNavHeight) * 2;
}

.hiddenWrapper {
  display: none;
  height: 0;
  transition: height 0.2s;
}

.header {
  color: var(--color1);
  font-size: 2.5rem;
  letter-spacing: -0.1rem;
  line-height: 0.9;
  max-width: calc(var(--maxBodyWidth) + 6rem);
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

.loadingText {
  color: var(--color1);
}

.timeline {
  color: var(--color3);
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.border {
  width: 100%;
  content: '';
  border-top: solid 1px #c4c4c4;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.emptyState {
  display: flex;
  justify-content: center;
}

.emptyState > h3 {
  text-transform: capitalize;
  font-family: var(--fontFamilySansSerif);
  padding: 1rem;
}
.emptyState > h3::after {
  content: unset;
}

@media (min-width: 768px) {
  .overlay {
    animation: none;
    font-family: var(--fontFamilySansSerif);
  }

  .content {
    animation: slideUp 0.5s;
    padding: var(--pageGutter);
    background: var(--colorBG);
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 15px;
    border: 2px ​solid var(--color1);
    z-index: var(--zIndexModalLevel);
  }

  .content::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 1025px) {
  .overlay {
    animation: none;
    font-family: var(--fontFamilySansSerif);
  }

  .content {
    animation: slideDown 0.5s;
    width: 50vw;
    height: 50vh;
    margin: 0 auto;
    position: absolute;
    top: 4rem;
    left: 30%;
    padding: var(--pageGutter);
    background: var(--colorBG);
    overflow: auto;
    right: 50%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 15px;
    border: 2px ​solid var(--color1);
    z-index: var(--zIndexModalLevel);
  }

  .content::-webkit-scrollbar {
    display: none;
  }
}

@media (min-width: 1225px) {
  .overlay {
    animation: none;
    font-family: var(--fontFamilySansSerif);
  }

  .content {
    animation: slideDown 0.5s;
    width: 30vw;
    height: 50vh;
    margin: 0 auto;
    position: absolute;
    top: 4rem;
    left: 30%;
    padding: var(--pageGutter);
    background: var(--colorBG);
    overflow: auto;
    right: 50%;
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 15px;
    border: 2px ​solid var(--color1);
    z-index: var(--zIndexModalLevel);
  }

  .content::-webkit-scrollbar {
    display: none;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  0% {
    height: 0vh;
  }
  100% {
    height: 50vh;
  }
}
