.error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  max-width: 75vw;
  min-height: calc(100vh - 289px);
  padding: calc(var(--headerHeight) + 2rem) 0 3rem;
}

.code {
  align-self: center;
  font-family: var(--fontFamilyHeading);
  font-size: 35vw;
  line-height: 1;
  padding-bottom: 0.5em;
}

.text {
  align-self: flex-end;
  text-align: right;
}

.title {
  font-size: 1.2rem;
}

.link {
  border: none;
  color: var(--color3);
  font-size: 1.15rem;
}

.reloadButton {
  width: 30%;
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

@media (min-width: 768px) {
  .error {
    max-width: 35rem;
    min-height: calc(100vh - 176px);
  }

  .code {
    font-size: 15rem;
  }
}
