.wrapper {
  padding: 0.5rem;
  font-family: var(--fontFamilySansSerif);
  margin-bottom: 1.3rem;
  display: flex;
}

.hiddenWrapper {
  display: none;
}

.contentContainer {
  display: flex;
  flex: 2;
  flex-direction: column;
  margin-left: 1.5rem;
  position: relative;
}

.commentLink {
  border: none;
  color: var(--colorFG);
  opacity: 0.8;
}

.commentLink:hover {
  opacity: 0.8;
  color: var(--color1);
}

.headlineContainer {
  display: flex;
  gap: 2rem;
  align-items: baseline;
}

.headline {
  color: var(--colorFG);
  font-weight: bold;
  max-width: 15rem;
}

.content {
  color: white;
  line-height: 1.5;
  margin-top: 0.3rem;
}

.closeButton {
  background: transparent;
  border: none;
  padding: 0;
  color: var(--colorArticleFont);
  font-weight: bold;
  opacity: 0.75;
}

.closeButton:hover {
  opacity: 1;
  color: var(--color3);
}

.timeStamp {
  display: flex;
  gap: 16px;
  color: var(--color3);
  font-size: 0.85rem;
}

.newAlert {
  color: var(--color1colorFG);
}

@media (min-width: 768px) {
  .headlineContainer {
    gap: 9rem;
  }
}
