:root {
  /* Colors  */
  --colorFG: hsl(240, 27%, 18%);
  --colorBG: hsl(210, 100%, 99%);
  --colorBGA: hsl(203, 89%, 96%);
  --color1: hsl(12, 66%, 61%);
  --color2: hsl(12, 66%, 61%);
  --color3: #dd745a;
  --colorComplimentaryBlue: hsl(228, 66%, 61%);
  --colorShadow: hsl(203, 89%, 96%);
  --colorFGRule: var(--colorFG);
  --colorGradient: linear-gradient(
    0.25turn,
    hsla(0, 0%, 0%, 0.75),
    hsla(0, 0%, 0%, 0.35),
    hsla(0, 0%, 0%, 0),
    hsla(0, 0%, 0%, 0.75)
  );

  /* equivalent to colorFGRule var, but without opacity */
  --colorGrey: #737079;
  --colorDarkGrey: #969696;
  --colorLightGrey: #c4c4c4;
  --colorArticleFont: var(--colorFG);
  --colorSuccess: green;
  --colorError: #e3094e;
  --colorErrorLight: #e3094e;
  --colorSecondaryBtn: #f2f2f2;
  --pageGradient: linear-gradient(
    190deg,
    var(--colorShadow),
    rgba(255, 255, 255, 0) 60vh
  );

  /* Elements */
  --smallHeight: 16px;
  --mediumHeight: 24px;
  --largeHeight: 32px;
  --headerHeight: 75px;
  --mobileBottomNavHeight: 61px;
  --footerHeight: 110px;
  --authorHeight: 93px;
  --pageGutter: 1.25rem;
  --homePageTopMargin: 7.5rem;
  --maxContainerWidth: 1250px;
  --maxBodyWidth: 1000px;
  --maxReadWidth: 750px;
  --verticalSpacer: 1.5rem;
  --CommPostPadding: 0.15rem 2.5rem;
  --subtleBoxShadow: rgba(0, 0, 0, 0.15) 0px 3px 4px;

  /* Type */
  --fontFamilyHeading: Playfair Display, serif;
  --fontFamilySerif: Domine, serif;
  --fontFamilySansSerif: Lato, sans-serif;

  /* z-index vars */
  --zIndexBehind: -1;
  --zIndexBase: 0;
  --zIndexLevelOne: 5;
  --zIndexLevelTwo: 200;
  --zIndexLevelThree: 300;
  --zIndexLevelFour: 400;
  --zIndexLevelFive: 500;
  --zIndexModalLevel: 9999999;
  --zIndexNotificationsLevel: 99999999;

  /* Media queries */
  --tabletUp: 768px;
  --desktopUp: 1025px;
}

svg:not(:root) {
  overflow: visible;
}

@media (min-width: 768px) {
  :root {
    --pageGradient: linear-gradient(
      190deg,
      var(--colorShadow),
      transparent 100vh
    );
  }
}
