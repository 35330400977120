.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--colorBGA);
  width: 80%;
  margin: 0 auto;
  padding: 1rem;
  border: solid 2px var(--colorFG);
  box-shadow: 0px 0px 15px var(--colorDarkGrey);
}

.header {
  font-family: var(--fontFamilySansSerif);
  color: var(--color1);
  font-size: 3rem;
  letter-spacing: -0.1rem;
  line-height: 0.9;
  margin: 1rem auto 1rem;
  max-width: calc(var(--maxBodyWidth) + 6rem);
  text-align: center;
  text-transform: uppercase;
}

.text {
  padding: 0.25rem;
  margin-bottom: 0.25rem;
  text-align: center;
  font-family: var(--fontFamilySansSerif);
  font-size: 18px;
}

.textEnd {
  margin-bottom: 2rem;
}

.buttonRow {
  display: flex;
  gap: 20px;
  margin-bottom: 2rem;
}

.buttonLink {
  text-decoration: none;
  border: none;
}

@media (min-width: 768px) {
  .wrapper {
    width: 60%;
    padding: 5rem;
  }

  .header {
    font-size: 4rem;
  }

  .text {
    font-size: 1.5rem;
  }

  .secondaryButton,
  .regularButton {
    font-size: 1rem;
  }
}

@media (min-width: 1025px) {
  .wrapper {
    width: 40%;
    padding: 5rem;
  }

  .header {
    font-size: 4rem;
  }

  .text {
    font-size: 1.5rem;
  }

  .secondaryButton,
  .regularButton {
    font-size: 1rem;
  }
}
