.defaultComment {
  margin: calc(var(--smallHeight) * 0.75) var(--smallHeight);
}

.commentHeader {
  display: flex;
  justify-content: space-between;
}

.commentBody {
  font-family: var(--fontFamilySansSerif);
}

.commentBody p {
  font-size: 1rem;
  line-height: 1.25;
  margin: 0 0 calc(var(--verticalSpacer) * 0.75);
}

.authorSection {
  display: flex;
  justify-content: space-between;
}

.replyButton {
  background-color: unset;
  border: none;
  margin-left: -2px;
  padding: 2px;
}

.pointerButton {
  cursor: pointer;
}

.author > p {
  margin: 0;
}

.authorAside {
  display: flex;
  flex-direction: column;
}

.authorLink,
.fauxAuthorLink {
  margin-bottom: 2px;
  border-bottom: none;
}

.authorLink:hover {
  margin-bottom: 1px;
  border-bottom: 1px solid var(--color2);
}

.authorUsername {
  font-weight: bold;
}

.authorInfo {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  padding-left: 2.5rem;
  padding-bottom: 0.5rem;
}

.ancestorAuthorInfo {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  padding-left: 0.5rem;
  padding-bottom: 0.5rem;
}

.authorAvatar {
  position: absolute;
  left: -0.1rem;
  top: -0.4rem;
}

.ancestorAuthorAvatar {
  position: absolute;
  left: -1.9rem;
  top: -0.6rem;
  border-radius: 50%;
  border-bottom: solid 38px var(--colorBG);
  border-top: solid 5px var(--colorBG);
  height: 36px;
  width: 36px;
}

.authorInfo h4 {
  color: var(--colorFG);
  margin: 0;
}

.authorInfo span {
  color: var(--colorFGRule);
}

.authorInfoSpacer {
  padding: 0px 5px;
}

.authorAside > p {
  line-height: 12px;
}

.readMore {
  cursor: pointer;
  color: var(--color3);
  margin-bottom: 4px;
  margin-left: 12px;
  padding-left: 24px;
}

.green {
  color: var(--color3);
}

.hr {
  color: var(--color3);
  margin-top: 24px;
  width: 95%;
}

.pointer {
  cursor: pointer;
  position: relative;
}

.commentOptionsContainer {
  position: relative;
}

.commentOptions {
  position: absolute;
  background-color: var(--colorBGA);
  box-shadow: var(--subtleBoxShadow);
  padding: 1rem 1.5rem;
  min-width: 8rem;
  right: 0.5rem;
  top: 1.5rem;
  z-index: 99;
}

.voteActions {
  display: flex;
  align-items: center;
}

.voteButtons {
  display: flex;
  flex-direction: column;
}

.voteButton {
  align-items: center;
  background-color: unset;
  border: none;
  padding: 1px;
}

.voteCount {
  margin: 0 2px;
}

.footerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 2rem;
  width: 100%;
}

.viewReplyThread {
  margin-left: calc(var(--smallHeight) / 8);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
}

.viewReplyThread a {
  padding: 2px;
  margin: 0 2px;
  border-bottom: unset;
}

.viewThreadText {
  color: var(--color3);
}

.replySection {
  display: flex;
  align-items: center;
}

.flagItem {
  cursor: pointer;
  list-style: none;
}

.cancel {
  background-color: unset;
  border: none;
  color: var(--colorFG);
}

.commentReplies {
  color: var(--colorFG);
  margin-left: 5px;
}

.replyingTo {
  display: flex;
  margin-bottom: 4px;
  font-family: var(--fontFamilySansSerif);
  color: var(--colorFGRule);
}

.replyingLink {
  color: var(--color1);
  margin-left: 4px;
  border-bottom: none;
}

.replyingLink:hover {
  margin-bottom: 1px;
  border-bottom: 1px solid var(--color2);
}

/*
 below is taken from
 https://github.com/TheDipp/www.thedipp.com/blob/3a685ce8a087cd9ebc632e84ed4245d8c5d7dd1f/pages/_app/styles.css#L123-L136
 */
.linkButton {
  font-family: var(--fontFamilySansSerif);
  font-size: 13px;
  font-weight: bold;
  line-height: 1.5;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
  text-align: center;
  border: unset;
  background: var(--colorBGA);
  border-radius: 0;
  color: var(--colorFG);
  display: flex;
  margin: 0 0 1rem;
  padding: 1.1rem 1.5rem;
  flex: 0 0 100%;
  min-width: 300px;
  display: flex;
  justify-content: center;
}

.accountOptions > button {
  width: 100%;
}

.modalHeader {
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}

.modalHeader:after {
  height: 0px;
}

.modalCopy {
  padding-bottom: var(--smallHeight);
}

.subscribeBanner {
  color: var(--color3);
}

.usernameModal {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usernameModalForm {
  height: 100%;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-bottom: var(--largeHeight);
}

@media (min-width: 768px) {
  .replySection {
    width: 100%;
  }
  .usernameModalForm {
    width: 70%;
    margin-bottom: calc(var(--largeHeight) * 3);
  }

  .footerContainer {
    justify-content: flex-start;
    width: 100%;
  }
  .hr {
    color: var(--colorBG);
  }

  .commentOptions {
    position: absolute;
    background-color: var(--colorBGA);
    box-shadow: var(--subtleBoxShadow);
    padding: 1rem;
    right: -8rem;
    top: 1.5rem;
  }
}
