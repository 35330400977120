.container {
  animation: editorSlideIn 0.3s;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--colorBG);
  z-index: 1050;
}

.inputContainer {
  position: relative;
  margin-bottom: 0.75rem;
}

.topicDropdownContainer {
  position: absolute;
  left: 5%;
  border-radius: 10px;
  z-index: 999;
  background-color: var(--colorBG);
  width: 90%;
  box-shadow: var(--subtleBoxShadow);
}

.topicDropdownContainer::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.actions {
  display: flex;
  justify-content: space-between;
  margin-top: -1rem;
  padding: 16px;
}

.submit {
  border: 2px solid var(--color3);
  background-color: var(--color3);
  color: var(--colorBG);
}

.disabledSubmitButton {
  opacity: 0.3;
}

.disabledSubmitButton:hover {
  cursor: auto;
}

.topLevelContainer {
  position: relative;
}

.serverError {
  position: absolute;
  top: 400%;
  left: 2%;
  color: red;
}

.editorContainer {
  padding: 16px;
  overflow-y: auto;
  padding-bottom: 0;
}

.titleInput {
  width: 100%;
  border: solid 1px var(--color3);
}

.titleCharCount {
  position: absolute;
  bottom: -1.5rem;
  right: 3%;
}

.titleInputContainer {
  position: relative;
}

.titleCharCountError {
  color: red;
}

.titleInputError {
  position: absolute;
  top: 95%;
  left: 3%;
  color: red;
  font-family: var(--fontFamilySansSerif);
  font-size: 0.85rem;
}

.hidden {
  display: none;
}

.input {
  border: 1px solid var(--color3);
  color: var(--colorFG);
  background-color: var(--colorBG);
  border-radius: 16px;
  margin-top: 1rem;
  height: auto;
  padding: 0.5rem;
  width: 100%;
  outline: none;
  overflow: hidden;
  min-height: 200px;
}

.input > p {
  text-align: unset;
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  /* word-break: break-all; */
  white-space: normal;
}

.clearingX {
  position: absolute;
  left: 88%;
  top: 25%;
  padding: 0;
  border: 0;
  background: transparent;
  color: #fff;
  font-size: 14px;
}

.clearingX:before {
  position: absolute;
  bottom: 0;
  right: 1.5rem;
  content: '';
  width: 100%;
  height: 100%;
  margin-left: 1rem;
  background-color: #fff;
  opacity: 0.7;
}

.hiddenClearingX {
  display: none;
}

.charCountError {
  color: red;
}

.inputControls {
  display: flex;
  justify-content: flex-end;
}

.commentStatus {
  width: 50%;
  left: 25%;
  position: absolute;
  top: 25%;
  font-family: var(--fontFamilySansSerif);
  color: var(--colorBG);
}

.commentSent,
.commentError {
  border-radius: 4px;
  padding: 0.5rem 0;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
}

.commentSent {
  background-color: var(--color3);
}
.commentError {
  display: flex;
  background-color: var(--colorError);
  text-align: center;
  vertical-align: middle;
}
.commentError button {
  width: 10px;
  background-color: var(--colorError);
  color: var(--colorSecondaryBtn);
}
.commentError button:hover {
  background-color: #720000;
}

.replyContainer {
  margin-top: 5px;
}

.slideOut {
  animation: alertSlideOut 0.3s;
}

.slideIn {
  animation: alertSlideIn 0.3s;
}

@media (min-width: 768px) {
  .container {
    border: 1px solid var(--color1);
    border-style: solid none none solid;
    top: calc(var(--headerHeight) + 3rem);
    width: 400px;
    right: 0;
  }

  .topicDropdownContainer {
    left: 5%;
    width: 55%;
    overflow: hidden;
  }

  .clearingX {
    position: absolute;
    left: 55%;
    top: 25%;
    padding: 0;
    border: 0;
    background: transparent;
    color: #fff;
    font-size: 14px;
  }

  .serverError {
    position: absolute;
    top: 102%;
    left: 2%;
  }

  .titleInput {
    width: 60%;
    border: solid 1px var(--color3);
    margin-left: 0.85rem;
  }

  .titleCharCount {
    position: absolute;
    bottom: -1.5rem;
    right: 40%;
  }

  .titleInputError {
    position: absolute;
    left: 3%;
    top: 95%;
    color: red;
    font-family: var(--fontFamilySansSerif);
    font-size: 0.85rem;
  }
}

@keyframes alertSlideOut {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-300%);
  }
}

@keyframes alertSlideIn {
  0% {
    transform: translateY(-200%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes editorSlideIn {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
