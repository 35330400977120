.footer {
  background: var(--colorBGA);
  border-top: 1px solid;
  align-items: center;
  display: flex;
  left: 0;
  justify-content: space-between;
  margin: auto;
  padding: 2rem 1.5rem;
  position: relative;
  right: 0;
  width: 100%;
  z-index: var(--zIndexLevelTwo);
}

.contents {
  color: var(--colorFG);
  margin: auto;
  max-width: var(--maxBodyWidth);
  width: 100%;
}

.logo {
  background: var(--colorFG);
  display: block;
  mask: url(../../public/logo.svg);
  mask-size: 100% 100%;
  mask-position: center center;
  border: none;
  color: transparent;
  font-size: 1px;
  height: 25px;
  margin-bottom: 1rem;
  width: 110px;
  z-index: var(--zIndexLevelTwo);
}

.sections {
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

.section {
  border: none;
  font-family: var(--fontFamilySerif);
  margin-right: 1rem;
}

.social {
  border-top: 1px solid var(--colorBG);
  font-size: 1.4rem;
  padding-top: 1.5rem;
  text-align: right;
}

.socialLink {
  border: none;
  display: inline-block;
  font-family: var(--fontFamilySansSerif);
  font-size: 0.9rem;
  margin-left: 1rem;
  text-decoration: none;
}

.is-active {
  text-decoration: underline;
}

.copy {
  font-family: var(--fontFamilySansSerif);
  font-size: 0.6rem;
  margin-top: 1rem;
  opacity: 0.6;
}

@media (min-width: 768px) {
  .contents {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .sections {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
  }

  .social {
    border-top: none;
    padding-top: 0;
  }
}
