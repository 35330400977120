* {
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1.6;
  text-size-adjust: 100%;
}

body {
  background: var(--colorBG);
  color: var(--colorFG);
  font-family: var(--fontFamilySerif), serif;
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  scrollbar-width: none;
}

.body::-webkit-scrollbar {
  width: 0;
  height: 0;
  background: transparent;
  -webkit-appearance: none;
  display: none;
}

/* preventing weird input styling artifacts in Chrome */
input[type='text'],
[type='search'] {
  -webkit-appearance: none;
}

a {
  background: transparent;
  border-bottom: 1px solid var(--color2);
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  word-wrap: break-word;
}

h1,
h2,
h3 {
  font-family: var(--fontFamilyHeading);
  margin: 2rem 0 1rem;
  text-rendering: optimizeLegibility;
  line-height: 1.2;
}

h1 {
  font-size: 2rem;
}

h2 {
  color: var(--colorFG);
  font-size: 2.3rem;
  line-height: 1;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
}

h2:after {
  background: var(--colorFGRule);
  bottom: 0;
  content: '';
  height: 1px;
  position: absolute;
  right: 90%;
  left: 0;
  max-width: 60px;
}

h3 {
  color: var(--colorFG);
  font-size: 1.8rem;
  line-height: 1;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
}

h3:after {
  background: var(--colorFGRule);
  bottom: 0;
  content: '';
  height: 1px;
  position: absolute;
  right: 90%;
  left: 0;
  max-width: 60px;
}

p,
li {
  font-size: 1.05rem;
  line-height: 1.75;
  margin: 0 0 var(--verticalSpacer);
}

article {
  margin: 0 auto;
}

button,
.button {
  align-items: center;
  background: transparent;
  border: 2px solid var(--colorFG);
  border-radius: 1.5rem;
  color: var(--colorFG);
  cursor: pointer;
  display: inline-flex;
  font-family: var(--fontFamilySansSerif);
  font-size: 13px;
  font-weight: bold;
  line-height: 1.5;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
}

button.secondary,
.button.secondary {
  background: var(--color3);
  border: 1px solid var(--color3);
  color: var(--colorBG);
}

button.block,
.button.block {
  background: var(--colorBGA);
  border-radius: 0;
  color: var(--colorFG);
  display: flex;
  margin: 0 0 1rem;
  padding: 1.1rem 1.5rem;
}

.block.full {
  border: 2px solid;
  flex: 0 0 100%;
  min-width: 250px;
}

button.text,
.button.text {
  background: transparent;
  color: var(--colorFG);
  line-height: 1;
  padding: 0 1rem;
}

button:focus {
  outline: none;
}

cite {
  font-style: normal;
}

img {
  max-width: 100%;
  border: 0;
  -webkit-backface-visibility: hidden; /* Webkit fix */
  transform: translate3d(0, 0, 0); /* Firefox fix */
}

caption {
  display: block;
  font-family: var(--fontFamilySansSerif);
  font-size: 0.8rem;
  opacity: 0.6;
  padding: 0.5rem 1rem 0;
  text-align: right;
}

cite {
  display: block;
  font-family: var(--fontFamilySansSerif);
  font-size: 0.8rem;
  font-style: italic;
  opacity: 0.6;
  padding: 0.25rem 1rem 0;
  text-align: right;
}

iframe {
  border: 0;
  width: 100%;
}

hr {
  background: var(--colorFGRule);
  border: 1px;
  border-style: solid none none;
  margin-bottom: var(--verticalSpacer);
  width: 100%;
}

blockquote {
  border-left: 3px solid var(--color1);
  margin: var(--verticalSpacer) auto calc(var(--verticalSpacer) * 2);
  padding-left: 1.5rem;
}

ul,
ol {
  margin: 0 0 var(--verticalSpacer);
}

textarea {
  font-family: var(--fontFamilySansSerif);
  border: 2px solid;
}

textarea,
input {
  background: #fff;
  border-radius: 1.5rem;
  font-size: 1rem;
  padding: 0.75rem 1rem 0.75rem 1rem;
}

input {
  border: none;
}

textarea,
input:focus {
  outline: none;
}

@media (min-width: 768px) {
  p,
  li {
    font-size: 1.1rem;
    line-height: 1.8;
  }

  blockquote {
    margin-left: 1.5rem;
  }

  h1 {
    font-size: 2.75rem;
  }

  input {
    font-size: 0.95rem;
  }
}

/* Form Styles */
.formBlock {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  font-family: var(--fontFamilySansSerif);
  justify-content: space-between;
  margin-bottom: 3rem;
  width: 100%;
}

.formBlock:last-child {
  border-bottom: 0;
}

.formType {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.formTitle {
  flex: 1;
  font-family: var(--fontFamilyHeading);
  font-size: 2.15rem;
  line-height: 1.1;
}

.formTitle:after {
  width: 60px;
}

.formSubTitle {
  flex: 0 0 100%;
  font-size: 0.85rem;
  line-height: 1.2;
  margin: -0.75rem 0 1.5rem;
  opacity: 0.6;
}

.formSubTitle + .formSubTitle {
  margin-top: -1.25rem;
}

.form,
.formStatic,
.formGroup {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.form {
  margin-top: 1.25rem;
}

.form .formTitle {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
}

.formLabel {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  font-size: 0.85rem;
  justify-content: space-between;
  margin-bottom: 1rem;
  text-align: left;
}

.formLabel.left {
  align-items: flex-start;
}

.formLabel.right {
  align-items: flex-end;
}

.formLabel.full {
  align-self: stretch;
}

.formLabel.fit {
  flex: 0;
}

.formLabel.half {
  flex: 0 0 calc(50% - (var(--pageGutter) / 2));
  width: calc(50% - (var(--pageGutter) / 2));
}

.formLabel.half:nth-of-type(odd) {
  margin-right: var(--pageGutter);
}

.formLabel input {
  border: 2px solid;
  margin-top: 0.5rem;
}

.formLabelSub {
  font-size: 0.8rem;
  line-height: 1.3;
  margin-top: 0.5rem;
  opacity: 0.6;
}

.formStatic {
  padding: 1.5rem 0 3rem;
}

.formStatic:last-child {
  padding-bottom: 0;
}

.formStatic .formTitle {
  align-items: flex-end;
  border-bottom: 1px solid var(--colorFGRule);
  display: flex;
  font-size: 1.8rem;
  justify-content: space-between;
  line-height: 1.15;
  margin-bottom: 1rem;
  padding-bottom: 0.75rem;
  width: 100%;
}

.formStatic .formLabel {
  align-items: flex-end;
  flex-direction: row;
}

.formStaticText {
  font-size: 1rem;
}

.formSubmits {
  display: flex;
  flex: 0 0 100%;
  flex-direction: row-reverse;
  margin-top: 1rem;
}

.formSubmit {
  align-self: flex-end;
}

.errorHolder {
  background: var(--colorError);
  display: block;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 calc(var(--pageGutter) * -1);
  text-align: center;
  width: 100vw;
}

.successHolder {
  background: var(--color3);
  display: block;
  font-size: 0.9rem;
  font-weight: bold;
  margin: 0 calc(var(--pageGutter) * -1);
  text-align: center;
  width: 100vw;
}

.errorMessage {
  display: block;
  padding: 0.5rem 1rem;
}

@media (min-width: 768px) {
  .formBlock {
    margin: 2rem 0 5rem;
  }

  .formBlock.row {
    flex-direction: row;
  }

  .row .formType {
    align-items: flex-start;
    flex: 0 0 45%;
    flex-direction: column;
  }

  .form .formTitle {
    font-size: 1.75rem;
  }

  .formTitle {
    margin-top: 0;
  }

  .formSubTitle {
    font-size: 0.9rem;
    max-width: 100%;
  }

  .row .formSubTitle {
    max-width: 70%;
  }

  .errorHolder {
    margin: 0;
    width: 100%;
  }

  .formStatic {
    padding: 0rem 0 4.5rem;
  }
}

/* Misc Custom */
.pad {
  padding: 1rem;
}

.legalText {
  font-family: var(--fontFamilySansSerif);
  font-size: 0.8rem;
  line-height: 1.23;
  margin-top: 1.5rem;
  opacity: 0.45;
  text-align: right;
  width: 100%;
}

.regular,
.wide,
.full {
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--verticalSpacer);
}

.regular img,
.wide img,
.full img {
  object-fit: contain;
  width: 100%;
}

div.wide {
  grid-column: 2 / -2;
}

div.full {
  grid-column: 1 / -1;
}

div.gallery {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  grid-column: 2 / -2;
  justify-content: center;
  margin-bottom: var(--verticalSpacer);
}

div.galleryFlex {
  display: inherit;
  width: 50%;
}

caption.galleryCaption {
  flex: 0 0 100%;
}

.embed {
  margin: 0 0 var(--verticalSpacer);
}

.embed > * {
  margin-left: auto !important;
  margin-right: auto !important;
  min-width: 0 !important;
}

.embed.video {
  padding-top: 56%;
  position: relative;
}

.embed.video iframe {
  bottom: 0;
  height: 100%;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.absolute {
  position: absolute;
}

.font-sans {
  font-family: var(--fontFamilySansSerif);
}

.font-serif {
  font-family: var(--fontFamilySerif);
}

.font-heading {
  font-family: var(--fontFamilyHeading);
}

.feed {
  margin-bottom: 4rem;
  padding: 0 var(--pageGutter);
}

.topicFeed {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: calc(var(--headerHeight) + 1rem) auto 4rem;
  max-width: var(--maxContainerWidth);
  padding: 0 var(--pageGutter);
}

amplify-federated-buttons {
  display: none;
}

#sign-in-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  margin-top: 20vh;
}

.shadow {
  background: var(--colorBG);
}

.hidden {
  transform: translateY(-100%);
}

.__mobiledoc-editor.__has-no-content::after {
  content: attr(data-placeholder);
  color: var(--colorFGRule);
  cursor: text;
  position: relative;
  top: -1.5rem;
}

.ProseMirror {
  position: relative;
  font-family: var(--fontFamilySansSerif);
  font-size: 1.05rem;
}

.ProseMirror {
  outline: 0;
  min-height: 180px;
  word-wrap: break-word;
  white-space: pre-wrap;
  white-space: break-spaces;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  font-feature-settings: 'liga' 0; /* the above doesn't seem to work in Edge */
}

.ProseMirror p {
  font-size: 1rem;
  line-height: 1.25;
  margin: 0 0 calc(var(--verticalSpacer) / 4);
}

.ProseMirror pre {
  white-space: pre-wrap;
}

.ProseMirror li {
  position: relative;
}

.ProseMirror.ProseMirror-focused {
  outline: 0;
}

.ProseMirror-hideselection *::selection {
  background: transparent;
}
.ProseMirror-hideselection *::-moz-selection {
  background: transparent;
}
.ProseMirror-hideselection {
  caret-color: transparent;
}

.ProseMirror-selectednode {
  outline: 2px solid #8cf;
}

/* Make sure li selections wrap around markers */

li.ProseMirror-selectednode {
  outline: none;
}

li.ProseMirror-selectednode:after {
  content: '';
  position: absolute;
  left: -32px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  border: 2px solid #8cf;
  pointer-events: none;
}

.ProseMirror .empty-node::before {
  position: absolute;
  color: #aaa;
  cursor: text;
}

.ProseMirror p.empty-node:first-child::before {
  content: attr(data-placeholder);
}
