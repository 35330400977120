.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--colorBG);
  border: 1px solid var(--color1);
  border-radius: 4px;
  padding: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  text-align: center;
}

.header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.dismissBtn {
  padding: 5px;
  background-color: unset;
  border: none;
}

.childWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@media (min-width: 768px) {
  .container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    width: 600px;
    background-color: var(--colorBG);
    border: 1px solid var(--color1);
    border-radius: 4px;
    padding: 16px;
    max-height: 600px;
    z-index: 1000;
  }

  .header {
    bottom: calc(var(--largeHeight));
  }
}
