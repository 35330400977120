.wrapper {
  background: var(--colorLightGrey);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.initials {
  text-transform: capitalize;
  font-size: 1.2rem;
  color: var(--color1);
}
