.mobileBottomContainer {
  position: fixed;
  bottom: 0;
  bottom: env(safe-area-inset-bottom);
  height: 61px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background: var(--colorBGA);
  z-index: var(--zIndexModalLevel);
  padding-top: env(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}

.mobileBottomContainerHidden {
  display: none;
}

.navIconContainer {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  font-size: 26px;
}

.navIcon {
  padding: 20px;
  cursor: pointer;
  background: none;
  border: none;
  overflow: visible;
  margin-top: 8px;
}
.navIcon:focus-visible {
  outline: solid 2px var(--color3);
}

.plusSignIcon {
  padding-bottom: 1.5rem;
  margin-right: 0.5rem;
  background: transparent;
}

.plusSignIcon:hover {
  opacity: 0.7;
}

.navIconLink {
  text-decoration: none;
  border-bottom: none;
}

.emptyButton {
  padding: 0;
  background: transparent;
  border: none;
}

.badge {
  position: absolute;
  top: -5px;
  right: 0px;
  padding: 5px 5px;
  border-radius: 50%;
  background: red;
  color: white;
  box-shadow: 0px 0px 11px red;
}

.hiddenBadge {
  display: none;
}

@media (min-width: 1025px) {
  .mobileBottomContainer {
    display: none;
  }
}
