.container {
  animation: editorSlideIn 0.3s;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: var(--colorBG);
  z-index: 1050;
  overflow-y: auto;
}

.actions {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--color3);
  padding: 16px;
}

.submit {
  border: 2px solid var(--color3);
  background-color: var(--color3);
  color: var(--colorBG);
}

.disabledSubmitButton {
  background-color: rgba(59, 93, 55, 0.99);
}

.disabledSubmitButton:hover {
  cursor: auto;
}

.editorContainer {
  padding: 16px;
  overflow-y: auto;
}

.input {
  border: 1px solid var(--color3);
  color: var(--colorFG);
  background-color: var(--colorBG);
  border-radius: 16px;
  margin-top: 1rem;
  height: auto;
  padding: 0.5rem;
  width: 100%;
  outline: none;
  overflow: hidden;
  min-height: 200px;
  max-height: 45vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.input::-webkit-scrollbar {
  scrollbar-width: none;
}

.input > p {
  text-align: unset;
  font-size: 1rem;
  line-height: 1.4;
  margin: 0;
  /* word-break: break-all; */
  white-space: normal;
}

.inputError {
  border: 1px solid var(--color2);
}

.charCountError {
  color: var(--color2);
}

.inputControls {
  display: flex;
  justify-content: flex-end;
}

.commentStatus {
  width: 50%;
  left: 25%;
  position: absolute;
  top: 25%;
  font-family: var(--fontFamilySansSerif);
  color: var(--colorBG);
}

.commentSent,
.commentError {
  border-radius: 4px;
  padding: 0.5rem 0;
  text-align: center;
  vertical-align: middle;
  text-transform: uppercase;
}

.commentSent {
  background-color: var(--color3);
}
.commentError {
  display: flex;
  background-color: var(--colorError);
  text-align: center;
  vertical-align: middle;
}
.commentError button {
  width: 10px;
  background-color: var(--colorError);
  color: var(--colorSecondaryBtn);
}
.commentError button:hover {
  background-color: #720000;
}

.replyContainer {
  margin-top: 5px;
}

.slideOut {
  animation: alertSlideOut 0.3s;
}

.slideIn {
  animation: alertSlideIn 0.3s;
}

@media (min-width: 768px) {
  .container {
    border: 1px solid var(--color1);
    border-style: solid none none solid;
    top: calc(var(--headerHeight) + 3rem);
    width: 400px;
    right: 0;
  }
}

@keyframes alertSlideOut {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-300%);
  }
}

@keyframes alertSlideIn {
  0% {
    transform: translateY(-200%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes editorSlideIn {
  0% {
    transform: translateY(100%);
  }

  100% {
    transform: translateY(0);
  }
}
