.header {
  height: var(--headerHeight);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background 0.5s, box-shadow 0.5s, transform 0.5s;
  isolation: isolate;
  z-index: var(--zIndexLevelFive);
}

.constrain {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: var(--maxContainerWidth);
  padding: 0 1rem;
}

.checkbox {
  display: none;
}

.left,
.right {
  align-items: stretch;
  display: flex;
  justify-content: center;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
}

.navIconContainer {
  display: none;
}

.navIconContainerHidden {
  display: none;
}

.logo {
  background: var(--colorFG);
  mask: url(../../public/logo.svg);
  mask-size: 100% 100%;
  mask-position: center center;
  border: none;
  color: transparent;
  font-size: 1px;
  height: var(--headerHeight);
  margin: 0.35rem 8px 0 1rem;
  width: 130px;
  z-index: var(--zIndexLevelTwo);
}

.navIcon {
  background: none;
  border: none;
  padding: 0;
  overflow: visible;
}

.plusSignIcon {
  margin-bottom: 0.55rem;
}

.plusSignIcon:hover {
  opacity: 0.7;
}

.navIconLink {
  text-decoration: none;
  border-bottom: none;
}

.userNameContainer {
  font-family: var(--fontFamilySansSerif);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-right: 16px;
}

.userName {
  display: none;
}

.pointer {
  position: absolute;
  top: 1.7rem;
  right: -8px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid var(--color1);
}

.editorPointer {
  position: absolute;
  top: 1.7rem;
  left: -8px;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 15px solid var(--color1);
}

.hiddenPointer {
  display: none;
}

.badge {
  position: absolute;
  top: -5px;
  right: 0px;
  padding: 5px 5px;
  border-radius: 50%;
  background: red;
  color: white;
  box-shadow: 0px 0px 11px red;
}

.hiddenBadge {
  display: none;
}

.signUp {
  display: none;
}

@media (min-width: 768px) {
  .signUp {
    display: unset;
  }
}

@media (min-width: 1025px) {
  .logo {
    margin: 0 1rem 0 0;
    width: 150px;
  }

  .navIconContainer {
    display: flex;
    gap: 36px;
    margin-left: 18px;
    align-items: center;
  }

  .userName {
    display: revert;
    font-family: var(--fontFamilySansSerif);
    font-size: 18px;
  }

  .checkbox:checked ~ .header .sections {
    transform: none;
  }
}
