.wrapper {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
}

.serverResponseError {
  position: absolute;
  top: -50%;
  left: 3%;
  color: red;
  font-family: var(--fontFamilySansSerif);
  font-size: 0.85rem;
}

.inputContainer {
  position: relative;
  width: 100%;
  padding: 0.25rem 0.5rem;
}
/* .inputArea {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #22223b;
  resize: none;
} */

.titleInput {
  width: 100%;
  border: solid 1px var(--color3);
}

.clearingButton {
  border: none;
}

.buttonRow {
  position: absolute;
  right: 0;
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
  justify-content: flex-end;
}

.cancelButton {
  border: none;
}

.cancelButton:hover {
  opacity: 0.55;
  transition: opacity 0.3s;
}

.replyButton {
  color: white;
  background-color: var(--color1);
  border: none;
  padding: 0 35px;
}

.replyButton:hover {
  opacity: 0.75;
  transition: opacity 0.3s;
}

/* -- CLOSED INPUT -- */

.closedInputRow {
  display: flex;
  flex-direction: row;

  gap: 2rem;
}

.closedInput {
  all: unset;
  width: 85%;
  height: 2rem;
  border: solid 1px black;
  border-radius: 15px;
  padding: 0.25rem 1rem;
}

.inputPrompt {
  opacity: 0.35;
}

@media (min-width: 768px) {
  .wrapper {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5rem;
  }

  .inputContainer {
    width: 80%;
    position: relative;
    padding: var(--CommPostPadding);
    margin: 0 auto;
  }

  /* .inputArea {
  width: 100%;
  border-radius: 5px;
  border: solid 1px #22223b;
  resize: none;
} */

  .clearingButton {
    border: none;
  }

  .titleInput {
    width: 60%;
    border: solid 1px var(--color3);
    margin-left: 0.85rem;
  }

  .buttonRow {
    position: absolute;
    right: 0;
    display: flex;
    margin-top: 1rem;
    gap: 1rem;
    justify-content: flex-end;
  }

  .cancelButton {
    border: none;
  }

  .cancelButton:hover {
    opacity: 0.55;
    transition: opacity 0.3s;
  }

  .replyButton {
    color: white;
    background-color: var(--color1);
    border: none;
    padding: 0 35px;
  }

  .replyButton:hover {
    opacity: 0.75;
    transition: opacity 0.3s;
  }

  /* -- CLOSED INPUT -- */

  .closedInputRow {
    display: flex;
    flex-direction: row;

    gap: 2rem;
  }

  .closedInput {
    all: unset;
    width: 85%;
    height: 2rem;
    border: solid 1px black;
    border-radius: 15px;
    padding: 0.25rem 1rem;
  }

  .inputPrompt {
    opacity: 0.35;
  }
}
